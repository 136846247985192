<template>
  <div class="bonus">
    <div class="bonus-left">
      <div class="bonus-left-it">{{item.type_text}}</div>
      <div class="bonus-left-it">{{item.time_text}}</div>
      <div class="bonus-left-it" v-if="item.extra_remark">{{item.extra_remark}}</div>
    </div>
    <div class="bonus-right" :class="[item.amount > 0 ? 'red':'black']">
      {{item.amount > 0 ? '+':''}}{{item.amount}}
    </div>
  </div>
</template>
<script>
import { nextTick } from 'vue'
export default {
  props: {
    item: Object
  },
  setup() {
    nextTick(() => {})
    return {}
  }
}
</script>

<style lang="less" scoped>
.bonus {
  background: #fff;
  // border-radius: 12px;
  padding: 15px;
  // overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-left{
    &-it{
      line-height: 1.8;
      &:first-of-type{
        font-weight: bold;
      }
    }

  }
  &-right{
    color: #333;
    font-size: 16px;
    &.red{
      color: red;
    }

  }


}
</style>
